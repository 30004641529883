import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const ProgresBar=()=> {
  return (
    <Box sx={{ width: '100%', paddingTop:'25px' }}>
      <LinearProgress />
    </Box>
  );
}
export default ProgresBar;