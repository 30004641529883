import { Box } from "@mui/material";
import Paper from '@mui/material/Paper';
import Header from "../../components/Header";
import AuthContext from '../../AuthContext';
import { useContext } from "react";
import React from "react";


const Form = () => {

  const { devices} = useContext(AuthContext);

  return (
    <Box m="20px">
      <Header title="Devices" subtitle="All Device is shown here" />

      <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: 250,
          height: 250,
        },
      }}
    >
      {devices.map((item)=> <Paper elevation={3}>{item.name}</Paper>)}
     
    </Box>
  
    </Box>
  );
};



export default Form;
