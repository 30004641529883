import { useState, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import AllDevice from "./scenes/devices/index";
import Active from "./scenes/devices/active";
import Inactive from "./scenes/devices/inactive";
import Form from "./scenes/form/index";
import Login from "./scenes/login";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import AuthContext from "./AuthContext";
import Snackbar from "./components/Snackbar";
import "./style.css";
import * as React from "react";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { logged } = useContext(AuthContext);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        {!logged && <Route path="*" element={<Login />} />}
      </Routes>
      {logged && (
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />

            <div className="app">
              <Sidebar isSidebar={isSidebar} />
              <main className="content">
                <Topbar setIsSidebar={setIsSidebar} />
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/all" element={<AllDevice />} />
                  <Route path="/active" element={<Active />} />
                  <Route path="/inactive" element={<Inactive />} />
                  <Route path="/form" element={<Form />} />
                </Routes>
              </main>
            </div>
          </ThemeProvider>
        </ColorModeContext.Provider>
      )}
      <Snackbar />
    </div>
  );
}

export default App;
