import { useTheme } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../theme";
import AuthContext from '../AuthContext';
import React, { useContext } from 'react';


const BarChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {devices,noActive,  } = useContext(AuthContext);  

  const activeCount=devices.length-noActive;

  const data = [
    {
      id: "All",
      label: "All",
      value: devices.length,
      color: "hsl(186, 70%, 50%)"
    },
    {
      id: "Active",
      label: "Active",
      value: activeCount,
      color: "hsl(104, 70%, 50%)"
    },
    {
      id: "Inactive",
      label: "Inactive",
      value: noActive,
      color: "hsl(56, 70%, 50%)"
    }

  ];
  return (
    <ResponsivePie
      data={data}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.5}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={2}
      borderColor={{ from: "color", modifiers: [["darker", 0.8]] }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      
      arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
    />
  );
};

export default BarChart;
