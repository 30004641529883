import React, { useContext} from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; 
import AuthContext from '../../AuthContext';
import ProgressBar from '../progress/ProgresBar';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { darken } from '@mui/material';




const Tablo = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {devices, loaded,
    noActive,} = useContext(AuthContext);  

  //   useEffect(()=>{
  //     fetchAllDevicesWithUpdatedDate();
  //  }, [update])


   const csvData= devices.map((res)=>({
    id:res.id,
    name:res.name,
    deEui:res.devEui,
    isVirtual:res.isVirtual?'True':'False',
    realIotDeviceId:res.realIotDeviceId,
    updatedAt:res.updatedAt
  }))

  const dateDiff=(dateInput)=>{
    const diff= new Date(new Date().toISOString())-new Date(dateInput);
    const diffHour=Math.floor(diff / 86400e3);
    return diffHour;
  }

  const noActiveData= csvData.filter((res)=>{
    return dateDiff(res.updatedAt)>1
  })

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };


  const handleExportNoActiveData = () => {
    csvExporter.generateCsv(noActiveData);
  };


  const columns = [
    {
      accessorKey: 'id',
      header: 'ID',
      size: 40,
    },
    
    {
      accessorKey: 'name',
      header: 'Name',
      size: 120,
    },
    {
      accessorKey: 'devEui',
      header: 'Dev EUI',
      size: 70,
    },
    {
      accessorKey: 'isVirtual',
      header: 'Is Virtual?',
      size: 40,
    },
    {
      accessorKey: 'realIotDeviceId',
      header: 'Real DevID',
      size: 70,
    },
    {
      accessorKey: 'updatedAt',
      header: 'Last Updated',
      size: 100,
      
      // Cell: ({ cell }) => (
      //   <Box
      //     sx={{
      //       backgroundColor: dateDiff(cell.getValue())>1 ? 'rgba(210,48,47,255)' : 'white',
      //       color: dateDiff(cell.getValue())>1 ? 'white' : '',
      //     }}
      //   >
      //     {cell.getValue()}
      //   </Box>
      // )
    },
  ];
  
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    filename:'inactive-devices',
    useBom: true,
    useKeysAsHeaders: true,
    //headers: columns.map((c) => c.header),
  };


  const csvExporter = new ExportToCsv(csvOptions);

  return (
    <>
     <Box m="20px">
      <Header title="Inactive Devices" subtitle="Managing the Inactive Devices" />
    {!loaded?<ProgressBar/>:
  
    <MaterialReactTable
      columns={columns}
      data={noActiveData}

      muiTableBodyProps={{
        sx: (theme) => ({
          '& tr:nth-of-type(odd)': {
            backgroundColor: darken(theme.palette.background.default, 0.1),
          },
        }),
      }}

      // muiTableBodyRowProps={ ({ row }) => ({
      //   sx:{
      //     backgroundColor: dateDiff(row.getValue('updatedAt'))>1 ? 'rgba(210,48,47,255)' : 'white',
      //   }
      // })}     
      enableRowSelection
      muiTableHeadCellProps={{
        //simple styling with the `sx` prop, works just like a style prop in this example
        sx: {
          fontWeight: 'bold',
          fontSize: '16px',
        },
      }}
      muiTableBodyCellProps={{
        //simple styling with the `sx` prop, works just like a style prop in this example
        sx: {
          fontWeight: 'normal',
          fontSize: '16px',
        },
      }}
      positionToolbarAlertBanner="bottom"
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
        >
          {noActive>0?
          <Button
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            //export all rows, including from the next page, (still respects filtering and sorting)
            onClick={handleExportNoActiveData}
            startIcon={<FileDownloadIcon />}
            variant="contained"
            color="error"
            sx={{ width: 200}}
          >
            Export Inactive 
          </Button>:''}
          <Button
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            //only export selected rows
            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
            startIcon={<FileDownloadIcon />}
            variant="contained"
            color="success"
            sx={{ width: 200}}
             >
            Export Selected
          </Button>
          </Box>
      )}
    />}
    </Box>
   </>
  );
};

export default Tablo;
