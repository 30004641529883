import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import React, { useContext } from 'react';

import DevicesIcon from '@mui/icons-material/Devices';
import Header from "../../components/Header";

import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import HistoryIcon from '@mui/icons-material/History';

import AuthContext from '../../AuthContext';
import CircularProgress from '@mui/material/CircularProgress';


const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { devices, loaded, noActive, setLoaded , setUpdate , setNoActive, update, } = useContext(AuthContext);

  const activeCount = devices.length - noActive;

  // const data= devices.map((res)=>({
  //   id:res.id,
  //   name:res.name,
  //   devEui:res.devEui,
  //   updatedAt:res.updatedAt
  // }))

  const newData= devices.sort(
    (p1, p2) => (p1.updatedAt < p2.updatedAt) ? 1 : (p1.updatedAt > p2.updatedAt) ? -1 : 0
    );


  const getLatestdata=[]
  for (let i = 0; i < 5; i++) {
    getLatestdata.push(newData[i])
  }


  const handleRefresh=()=>{
    setLoaded(!loaded)
    setUpdate(!update);
    setNoActive(0); 
  }
 

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to IoT dashboard" />

        {/* <Box>
          <Button onClick={handleRefresh}
            sx={{
              backgroundColor: colors.redAccent[400],
              color: 'white',
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <HistoryIcon sx={{ mr: "10px" }} />
            Refresh Data
          </Button>
        </Box> */}
         {loaded&&
        <Button
        
        onClick={handleRefresh}
        endIcon={<HistoryIcon />}
        variant="contained"
        color="warning"
        fontSize="16"
        sx={{ width: 200, fontSize:16}}
      >
        Refresh
      </Button>}
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="145px"
        gap="20px"
      >

        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {loaded ? <StatBox
            title={devices.length}
            subtitle="All Devices"
            progress="1"
            color={colors.greenAccent[500]}
            //increase="+14%"
            icon={
              <DevicesIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          /> : <CircularProgress />}
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {loaded ? <StatBox
            title={activeCount}
            subtitle="Active Devices"
            progress={activeCount / devices.length}
            color={colors.blueAccent[500]}
    
            icon={
              <DevicesIcon
                sx={{ color: colors.blueAccent[500], fontSize: "26px" }}
              />
            }
          /> : <CircularProgress color="success" />}
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {loaded ? <StatBox
            title={noActive}
            subtitle="Inactive Devices"
            progress={noActive / devices.length}
            color={colors.redAccent[500]}
            //increase="+5%"
            icon={
              <DevicesIcon
                sx={{ color: colors.redAccent[500], fontSize: "26px" }}
              />
            }
          /> : <CircularProgress color="error" />}
        </Box>
         <Box
          gridColumn="span 4"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Geography Based Devices
          </Typography>
          {loaded ? <Box height="540px">
            <GeographyChart isDashboard={true} />
          </Box>: <CircularProgress color="error" />}
        </Box> 

        {/* ROW 2 */}
        <Box
          gridColumn="span 5"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
         <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`1px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
             Latest 5 Device Events
            </Typography>
          </Box>
          {devices.length >0?loaded? getLatestdata.map((transaction, i) => (
    
            <Box
              key={`${transaction.id}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.id}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.name}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.devEui}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                {transaction.updatedAt}
              </Box>
            </Box>
          )): <CircularProgress color="error" />:''}
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 3"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
           <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`1px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="7px"
          >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "15px 30px 0 30px" }}

          >
            Device Status
          </Typography>
          </Box>
          {loaded?
          <Box height="450px" mt="-20px">
            {devices.length>0?
            <BarChart isDashboard={true} />:''}
          </Box> : <CircularProgress color="error" />}
        </Box>

      </Box>
    </Box>
  );
};

export default Dashboard;
