import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@mui/icons-material/Room';
import AuthContext from '.././AuthContext';
import React, { useContext } from 'react';
import MyMarker from "./MyMarker";




const GeographyChart = ({ isDashboard = false }) => {

  const { devices } = useContext(AuthContext);


  // eslint-disable-next-line array-callback-return
  const gpsData = devices.filter((item) => {
    if (item.extraProperties && item.extraProperties.location && item.extraProperties.location.lat) {
      return (

        item

      )
    }
  })


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const defaultProps = {
    center: {
      lat: 61.674056,
      lng: 27.248490
    },
    zoom: 7
  };


  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyD87Rztwug3VkTr3tqqiqKo0ZuDnZef-gI" }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      {gpsData.map((item) =>
     
        // <AnyReactComponent key={item.id}
        //   lat={item.extraProperties.location.lat}
        //   lng={item.extraProperties.location.long}
        //   color="error"
        //   onPress={()=>onPress(item.id)}

        // />
        <MyMarker key={item.id} lat={item.extraProperties.location.lat} lng={item.extraProperties.location.long} text={item.id} tooltip={item.name} />


      )}
    </GoogleMapReact>
  );
};

export default GeographyChart;


