import React from 'react';
import { createContext } from "react";
import axios from 'axios';
import { useState} from 'react';
import {useNavigate} from "react-router-dom";

const IotContext= createContext();

const AuthContextProvider=({children})=>{

    const navigate = useNavigate();

    const [noActive, setNoActive]= useState(0)
    const [logged,setLogged]=useState(false)
    const [authUser, setAuthUser]=useState({});
    const [devices, setDevices]=useState([{}]);
    const [loaded,setLoaded]=useState(false)
    const [update, setUpdate]=useState(false);
    const [open, setOpen] = useState(false);
    const [snack, setSnack] = useState({
      open:false,
      message:'',
      severity:'success',
    });

    const [formData, setFormData]=useState({
        username:'',
        password:'' });
     
    
          const onLogin =  (event) => {
                event.preventDefault();

                axios.post('https://tinksi.fi:3001/api/v4/login', {}, {
                  auth: {
                    username: formData.username,
                    password: formData.password
                  }
                })
                  .then((response) => {
                    setAuthUser(response.data)
                    setSnack({
                      open:true,
                      message:'Login success',
                      severity:'success'
                    })
   
                    setLogged(true)
                    navigate("/dashboard")
                  })
                  .catch((error) => {
                    setSnack({
                      open:true,
                      message:'Login failed',
                      severity:'error'
                    })
                  });
              };
  
          const dateDiff=(dateInput, id)=>{
      
              const diff= new Date(new Date().toISOString())-new Date(dateInput);
              const diffDay=Math.floor(diff / 86400e3);
              //console.log('Device id:'+id+'--->'+diffDay)
              if(diffDay>=1)
              {
              setNoActive(prevCount => prevCount+1)
              //console.log('Device id:'+id+'--->'+diffDay)
              setOpen(true)
            
              };
                  
          }
        
          const fetchDevices = async () => {
            const response = await axios.get("https://tinksi.fi:3001/api/v4/iot/devices", {
              auth: {
                username: formData.username,
                password: formData.password
              }
            });
          
            return response.data;
            
          };
    
          const fetchDeviceUpdatedDate = async (deviceId, updatedAt) => {
            //console.log(`Making request to /api/v4/iot/devices/${deviceId}/device-events...`);
            const response = await axios.get(
           
              `https://tinksi.fi:3001/api/v4/iot/devices/${deviceId}/device-events?page=0&per_page=1&sort=desc%3Aid`,
              {
                auth: {
                  username: formData.username,
                  password: formData.password
                },
              },
            );
            if(!response.data[0])
            {
              return updatedAt
            } else {

              return response.data[0].date.length > 0 ? response.data[0].date : null;
            }
          };
        
        const fetchAllDevicesWithUpdatedDate = async () => {
    
            const devices = await fetchDevices();
    
            const updatedDevicesPromises = devices.map(async (device) => {
              try {
                const updatedDate = await fetchDeviceUpdatedDate(device.id,device.updatedAt);
                dateDiff(updatedDate,device.id);
              
                return {
                  ...device,
                  updatedAt: updatedDate,
                };
                
              } catch (error) {
                return device;
              }
            });
          
            const updatedDevices = await Promise.all(updatedDevicesPromises);
          
            setDevices(updatedDevices);
            setLoaded(true)
            setTimeout(() => {
              setOpen(false)
            }, 2000);
            
         };

     const data={
            onLogin,
            logged,
            setLogged,
            authUser,
            devices,
            setDevices,
            loaded, setLoaded,
            formData,
            setFormData,
            update, setUpdate,
            fetchAllDevicesWithUpdatedDate,
            fetchDevices,
            noActive, setNoActive,
            open, setOpen, snack ,setSnack
    }

    return(
        <IotContext.Provider value={data}>
                {children}
        </IotContext.Provider>
    );
}
export {AuthContextProvider};
export default IotContext;